import styled from "styled-components"
import { MainColumn } from "components/MainColumn"
import { device } from "../../utils/breakpoints.js"
import AvenueImg from "components/AvenueImg"

export const HeaderSpacer = styled.div`
  padding-top: 0px;
  margin-bottom: 40px;

  @media ${device.desktop} {
    padding-top: 20px;
    margin-bottom: 60px;
  }

  @media ${device.large} {
    padding-top: 56px;
  }
`
export const HeaderContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.blue};
  position: relative;
  margin: 0 auto;

  @media ${device.desktop} {
    padding-top: 15px;
    padding-top: ${props => props.noImg && "23px"};
  }

  @media ${device.large} {
    padding-top: 65px;
  }
`

export const HeroImage = styled(AvenueImg)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  img {
    transform: scale(1.1);
  }
`

export const HeaderText = styled(MainColumn)`
  margin-top: 110px;
  color: white;
  padding-bottom: 150px;
  padding-bottom: ${props => props.internal && "10px"};
  margin-top: ${props => props.internal && "33px"};
  margin-bottom: ${props => props.internal && "40px"};
  margin-top: ${props => props.noImg && 0};
  padding: ${props => props.noImg && "33px 0 0 0"};
  position: relative;
  padding: ${props => !props.title && 0};
  @media ${device.tablet} {
    padding-bottom: ${props => props.internal && "30px"};
    padding-top: ${props => props.internal && "45px"};
    padding: ${props => props.noImg && "33px 0 0 0"};
    padding: ${props => !props.title && 0};
  }

  @media ${device.desktop} {
    padding-bottom: ${props => props.internal && "70px"};
    margin-top: ${props => props.internal && "90px"};
    margin-bottom: ${props => props.internal && "60px"};
    margin-top: ${props => props.noImg && "20px"};
    padding: ${props => props.noImg && "52px 0 0 0"};
    flex-direction: ${props => props.noImg && "row"};
    padding: ${props => !props.title && 0};
  }
  @media ${device.large} {
    margin-top: 138px;
    padding-bottom: 210px;
    padding-bottom: ${props => props.internal && "47px"};
    margin-top: ${props => props.internal && "134px"};
    margin-bottom: ${props => props.internal && "94px"};
    margin-top: ${props => props.noImg && "56px"};
    padding: ${props => props.noImg && "84px 0 0 0"};
    ${props => props.noImg && `margin-bottom: 56px`};
    padding: ${props => !props.title && 0};
  }
  h1 {
    font-size: 30px;
    margin-bottom: 20px;
    margin-bottom: ${props => props.noImg && "0px"} !important;

    color: ${props => (props.noImg ? props.theme.blue : "white")};
    height: 100%;
    line-height: 90%;
    font-weight: 700;
    z-index: 2;
    font-size: ${props => props.internal && "30px"};

    @media ${device.tablet} {
      width: 50%;
      width: ${props => props.noImg && "100%"};
    }
      
    @media ${device.desktop} {
      font-size: 70px;
      margin-bottom: 25px;
      font-size: ${props => props.internal && "42px"};
      width: ${props =>
        props.home
          ? "50%"
          : !props.excerpt
          ? "100%"
          : props.noImg
          ? "37%"
          : "100%"};
      padding-right: ${props => props.noImg && "40px"};
    }
    @media ${device.large} {
      // font-size: 96px;
      margin-bottom: 45px;
      // font-size: ${props => props.internal && "64px"};
      padding-right: ${props => props.noImg && "60px"};
    }

    :before {
      content: "";
      display: ${props => !props.noImg && "none"};
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;
      height: 100%;
      background: white;
    }
  }

  p {
    font-size: 20px;
    line-height: 140%;
    z-index: 2;

    ${props =>
      props.noImg &&
      `
      color:#5A5A5A;
      font-weight:300;
      line-height:150%;
      font-size:16px;
      margin-top:20px;
      letter-spacing:0.56px;
      `};

    @media ${device.tablet} {
      width: 40%;
      width: ${props => props.noImg && "100%"};
    }
    @media ${device.desktop} {
      font-size: 22px;
      font-size: ${props => props.noImg && "18px"};
      width: ${props => props.noImg && "66.666%"};
      margin-top: ${props => props.noImg && "0"};
    }
    @media ${device.large} {
      font-size: 24px;
      font-size: ${props => props.noImg && "20px"};
    }
      ${props =>
        props.internal &&
        `
        &:first-of-type {
          font-weight: 500;
          font-size: 18px;
          color: #000000;
          letter-spacing: 0;
          line-height: 24px;
          @media ${device.desktop} {
            font-size: 24px;
            color: #000000;
            letter-spacing: 0;
            line-height: 32px;
            padding-right: 60px;
            }
            }
            `};
  }
`

export const DiscoverButton = styled.button`
  padding: 0;
  outline: none;
  border: none;
  background: none;
  color: ${props => props.theme.green};
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  cursor: pointer;
  bottom: 53px;
  padding: 13px;

  @media ${device.desktop} {
    font-size: 14px;
    bottom: 77px;
  }

  :after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    height: 80px;
    top: 47px;
    background: ${props => props.theme.green};
    width: 1px;
    height: 62px;

    @media ${device.desktop} {
      top: 58px;
      height: 66px;
    }
  }
`
